import { Card, CardContent, CardHeader, Grid, Paper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LineChart, XAxis, YAxis, CartesianGrid, Line } from "recharts"


export const DeviceDetails = () => {
    const ref = useRef(null);

    const [width, setWidth] = useState(0);

    useEffect(() => {
        setWidth(
            ref.current.clientWidth - (
                parseInt(window.getComputedStyle(ref.current, null).getPropertyValue('padding-left').replace('px', '')) * 2
            ) - 8
        );

    }, []);

    let [metrics, setMetrics] = useState([])
    let [device, setDevice] = useState({})

    const { deviceId } = useParams();

    useEffect(() => {
        const metrics_url = `https://iot-metrics.h4h0.de/v1/device/${deviceId}`;
        const device_url = `https://iot-twin.h4h0.de/v1/device/${deviceId}`;
        fetch(metrics_url).then(response => response.json()).then(data => {
            setMetrics(data)
        })
        fetch(device_url).then(response => response.json()).then(data => {
            setDevice(data)
        })
        setInterval(() => {
            fetch(metrics_url).then(response => response.json()).then(data => {
                setMetrics(data)
            })
            // fetch(device_url).then(response => response.json()).then(data => {
            //     setDevice(data)
            // })
        }, 5000)
    }, [])


    return (
        <Grid item xs={12}>
            <Card>
                <CardHeader
                    title={`Device: ${device.model} (${device.name})`}
                />
                <CardContent ref={ref}>
                    <LineChart width={width} height={300} data={metrics.map(({ timestamp: { secs_since_epoch: timestamp }, value }) => { return { timestamp, value } })}>
                        <XAxis dataKey="timestamp" />
                        <YAxis />
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                        <Line type="monotone" dataKey="value" stroke="#82ca9d" />
                    </LineChart>
                </CardContent>
            </Card>
        </Grid>
    )
}
