import { Card, CardContent, CardHeader, Grid, List, ListItem, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PanoramaIcon from "@mui/icons-material/Panorama";
import { Link, useNavigate } from "react-router-dom"


const formatProperty = property => {
    if (typeof property == "boolean") {
        return property ? 'true' : 'false'
    }
    if (typeof property == "string") {
        return property
    }
    if (!isNaN(parseFloat(property)) && !Number.isInteger(property)) {
        return Math.round((property * 100)) / 100
    }

    return property || '---'
}

const parseTimestamp = ts => {
    if (ts) {
        const { $date: { $numberLong: timestamp } } = ts;

        if (timestamp == 0) {
            return '---'
        }
        var date = new Date(0);
        date.setUTCMilliseconds(timestamp);

        return date.toLocaleDateString() + ' - ' + date.toLocaleTimeString();
    }
    return '---'
}

const Device = ({ data: {
    _id: { $oid: id },
    device: { model, name },
    last_seen_at,
    properties: {
        desired: {
            updated_at: desired_updated_at,
            data: desired_properties
        },
        reported: {
            updated_at: reported_updated_at,
            data: reported_properties
        }
    } } }) => {

    const navigate = useNavigate();

    const properties_keys = [...new Set([...Object.keys(reported_properties), ...Object.keys(desired_properties)])]

    return (
        <Grid item xs={12} md={6}>
            <Card
                key={id}
                sx={{
                    margin: '5px',
                    width: '100%'
                }}
            >
                <CardHeader
                    avatar={<PanoramaIcon onClick={() => navigate(`/device/${id}`)} />}
                    title={`Device: ${model} (${name})`}
                    subheader={`ID: ${id}`}
                />
                <TableContainer component={CardContent}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Property</TableCell>
                                <TableCell>Reported</TableCell>
                                <TableCell>Desired</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {properties_keys.map(key => (
                                <TableRow
                                    key={key}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {key}
                                    </TableCell>
                                    <TableCell>{formatProperty(reported_properties[key])}</TableCell>
                                    <TableCell>{formatProperty(desired_properties[key])}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    Last seen
                                </TableCell>
                                <TableCell colSpan={2}>{parseTimestamp(last_seen_at)}</TableCell>
                            </TableRow>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    Last prop update
                                </TableCell>
                                <TableCell>{parseTimestamp(reported_updated_at)}</TableCell>
                                <TableCell>{parseTimestamp(desired_updated_at)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </Grid>
    )
}

export const DeviceOverview = () => {
    let [devices, setDevices] = useState([])

    useEffect(() => {
        fetch("https://iot-twin.h4h0.de/v1/devices").then(response => response.json()).then(data => {
            setDevices(data)
        })
        setInterval(() => {
            fetch("https://iot-twin.h4h0.de/v1/devices").then(response => response.json()).then(data => {
                setDevices(data)
            })
        }, 5000)
    }, [])

    return (
        <React.Fragment>
            {
                devices.map(data => <Device key={data._id.$oid} data={data} />)
            }
        </React.Fragment >
    )
}