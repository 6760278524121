import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";

import Layout from './components/Layout';
import { DeviceOverview } from './components/pages/DeviceOverview';
import { DeviceDetails } from './components/pages/DeviceDetails';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <DeviceOverview />,
  },
  {
    path: "/device/:deviceId",
    element: <DeviceDetails />,
  },
]);

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Layout>
        <RouterProvider router={router} />
      </Layout>
    </ThemeProvider>
  );
}

export default App;
